//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;