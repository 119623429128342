//Install
import React, { Component } from "react";
//Install
import Follow from "../Media/Image/003.png";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute k11s38" },
            //Element
            React.createElement( "img", { alt: "Bizi Takip Edin", title: "Bizi Takip Edin", src: Follow, width: "315", height: "40" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;