//Install
import React, { Component } from "react";
//Install
import Brand from "../Media/Image/004.png";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute e6x1b0", title: "Toilet Party Game", href: "/" },
            //Element
            React.createElement( "img", { alt: "Toilet Party Game", title: "Toilet Party Game", src: Brand, width: "294", height: "212" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;