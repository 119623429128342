//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Steam from "../Media/Image/006.png";
//Install
import Start from "../Media/Image/008.png";
//Install
import End from "../Media/Image/007.png";
//Install
import Bottom from "../Media/Image/009.png";

//Element
class Main extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 mt-5 position-absolute start-0 top-0 z207bq" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mc113r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 b179f6" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-2 m-0 w5351i" },
                                    //Element
                                    React.createElement( "iframe", { width: "500", height: "266", src: "https://www.youtube.com/embed/98R-Nr-S0wk?si=CItbc6NlSBlgwGfO", className: "float-start w-100 h-100 border-0" } )
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-2 pu860y" },
                                    //Element
                                    React.createElement( "img", { alt: "Steam'da", title: "Steam'da", src: Steam, width: "225", height: "auto" } )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-absolute start-0 top-0 s9sa85" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 um863l" },
                                //Element
                                React.createElement( "img", { alt: "Toilet Party Game", title: "Toilet Party Game", src: Start, width: "80", height: "auto" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-absolute dz75x6" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 um863l" },
                                //Element
                                React.createElement( "img", { alt: "Toilet Party Game", title: "Toilet Party Game", src: End, width: "80", height: "auto" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-auto p-0 m-0 position-absolute j7a04e" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 um863l" },
                                //Element
                                React.createElement( "img", { alt: "Toilet Party Game", title: "Toilet Party Game", src: Bottom, width: "80", height: "auto" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;